import segmentTrackedEvents from 'constants/segmentTrackedEvents';
import i18n, { FALLBACK_LANGUAGE } from 'i18n';
import { type UserApi, apiClient } from 'modules/api-client';
import {
  FooterContainer,
  MainContainer,
  PageContainer,
} from 'modules/signup/common';
import {
  AccountForm,
  ConfirmEmail,
  Header,
  PartnersFooter,
} from 'modules/signup/components';
import { useRef, useState } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { trackForLinkedIn } from 'utils/tracking';

export const Signup = () => {
  const [t] = useTranslation('signup');
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const language = i18n.resolvedLanguage ?? FALLBACK_LANGUAGE;

  const onChangeAccountForm = (key: string, value: string | null) => {
    const cb: { [k: string]: any } = {
      firstName: setFirstName,
      lastName: setLastName,
      email: setEmail,
      password: setPassword,
      recaptcha: setRecaptcha,
    };
    if (cb[key]) {
      cb[key](value);
    }
  };

  const resetRecaptcha = () => {
    recaptchaRef.current?.reset();
    setRecaptcha(null);
  };

  const onSubmit = () => {
    if (recaptcha === null) {
      toast.error(t('form.errors.recaptchaEmpty'));
      return;
    }
    setLoading(true);
    apiClient.user
      .signup(email, password, firstName, lastName, language, recaptcha)
      .then(async (resp: UserApi | Response) => {
        if (resp instanceof Response) {
          if (resp.status === 401) {
            const body = await resp.json();
            const emailAlreadyExists = body?.message?.includes(
              'This email has already been used to create an account',
            );
            if (emailAlreadyExists) {
              toast.error(t('form.errors.emailAlreadyExists'));
              setLoading(false);
              resetRecaptcha();
              return;
            }
          }
          throw new Error(t('form.toastError'));
        }

        analytics.track(segmentTrackedEvents.USER_EVENTS.SIGNED_UP_FREETRIAL);
        trackForLinkedIn();

        setTimeout(() => {
          setLoading(false);
          setCurrentStep(1);
        }, 3000);
      })
      .catch(() => {
        toast.error(t('form.toastError'));
      });
  };

  const renderStep = () => {
    if (currentStep === 1) {
      return <ConfirmEmail email={email} />;
    }
    return (
      <AccountForm
        firstName={firstName}
        lastName={lastName}
        email={email}
        password={password}
        onSubmit={onSubmit}
        onValueChange={onChangeAccountForm}
        loading={loading}
        recaptchaRef={recaptchaRef}
      />
    );
  };

  return (
    <PageContainer>
      <Header />

      <MainContainer>{renderStep()}</MainContainer>

      <FooterContainer>
        <PartnersFooter />
      </FooterContainer>
    </PageContainer>
  );
};
