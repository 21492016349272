import type { FLAGS } from 'constants/flags';
import {
  FeatureFlagsActionTypes,
  useFeatureFlags,
  useFeatureFlagsDispatch,
} from 'context/feature-flags/FeatureFlagsContext';
import { apiClient } from 'modules/api-client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { Store } from 'reducers';

export function useFeature<FlagValueType = any>(
  featureFlag: FLAGS,
): [FlagValueType, boolean] {
  const featureFlagsDispatch = useFeatureFlagsDispatch();
  const featureFlags = useFeatureFlags();
  const [featureFlagValue, setFeatureFlagValue] = useState<FlagValueType>(
    featureFlags[featureFlag],
  );
  const [loading, setLoading] = useState(false);
  const userToken: string = useSelector(
    (state: Store) => state.user.token || '',
  );

  useEffect(() => {
    if (!userToken) {
      return;
    }
    if (typeof featureFlagValue !== 'undefined') {
      setFeatureFlagValue(featureFlagValue);
      return;
    }
    setLoading(true);
    apiClient.flag
      .getAllFlags(userToken)
      .then((payload) => {
        featureFlagsDispatch({
          type: FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS,
          payload,
        });
        setFeatureFlagValue(payload[featureFlag] as FlagValueType);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [featureFlagValue, featureFlag, userToken, featureFlagsDispatch]);

  return [featureFlagValue, loading];
}
