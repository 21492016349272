import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getColors = (variant: 'blue' | 'red' | 'orange') => {
  switch (variant) {
    case 'blue':
      return {
        color: colors.blue400,
        background: colors.blue50,
        border: colors.blue200,
      };
    case 'red':
      return {
        color: colors.gray850,
        background: colors.red200,
        border: colors.red400,
      };
    case 'orange':
      return {
        color: colors.gray850,
        background: colors.yellow100,
        border: colors.yellow400,
      };
    default:
      return {
        color: colors.blue400,
        background: colors.blue50,
        border: colors.blue200,
      };
  }
};

const Wrapper = styled.div<{ variant: 'blue' | 'red' | 'orange' }>`
  padding-left 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => getColors(props.variant).color};
  background: ${(props) => getColors(props.variant).background};
  border: 1px solid  ${(props) => getColors(props.variant).border};
  line-height: 18px;
  width: fit-content;
  `;

const Badge = ({
  text,
  variant,
}: { text: string; variant?: 'blue' | 'red' | 'orange' }) => {
  return (
    <Wrapper variant={variant || 'blue'} role="status">
      {text}
    </Wrapper>
  );
};

export const New = () => {
  return <Badge text="New" />;
};

export const Beta = () => {
  const { t } = useTranslation('commonUi');

  return <Badge text={t('beta')} />;
};
