import { gql } from '@apollo/client';

export type GraphQLGroupFeaturesWorkspace = {
  id: string;
  name: string;
  totalAssets: number;
  parentWorkspaceId: number | null;
};

export type GraphQLFeatureManagement = {
  customLogoUrl: string;
  displayZones: boolean;
  displayAlerts: boolean;
  dashboards: {
    labelKey: string;
  }[];
} | null;

export type GraphQLGroupFeatures = {
  viewer: {
    id: string;
    organization: { id: string; name: string };
    workspaces: GraphQLGroupFeaturesWorkspace[];
    group: {
      id: string;
      name: string;
      featureManagement?: GraphQLFeatureManagement;
    };
  };
};

export type GraphQLUserLanguage = {
  viewer: {
    language: 'string';
  };
};

export const query = gql`
  query GroupFeatures {
    viewer {
      id
      organization {
        id
        name
      }
      workspaces {
        id
        name
        parentWorkspaceId
        totalAssets
      }
      group {
        id
        name
        featureManagement {
          displayAlerts
          displayZones
          customLogoUrl
          dashboards {
            labelKey
          }
        }
      }
    }
  }
`;

export const queryUserLanguage = gql`
  query queryUserLanguage {
    viewer {
      id
      language
    }
  }
`;
