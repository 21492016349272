import styled, { keyframes } from 'styled-components';

import colors, { primaryColors } from 'constants/colors';

import { Link } from 'react-router-dom';
import { css } from 'styled-components';

export const resizeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

export const InfoTitle = styled.div`
  font-size: 14px;
  display: flex;
  color: #929497;
`;

export const InfoNumber = styled.div`
  font-size: 24px;
  display: flex;
`;

export const Row = styled.div`
  display: flex;
`;

export const Cell = styled.div`
  color: #000000;
  display: flex;
  flex-direction: column;
  min-width: 80px;

  div + div {
    margin-top: 8px;
  }
  & + & {
    margin-left: 24px;
  }
`;

type CardSubtitleProps = {
  noMarginTop?: boolean;
};

export const CardSubtitle = styled.div<CardSubtitleProps>`
  color: #929497;
  margin-top: ${(props) => (props.noMarginTop ? '' : '16px')};
  margin-bottom: 16px;

  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const TopCard = styled.div`
  color: #929497;
  margin-top: 3px;
  margin-bottom: 13px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const OverlayContainer = styled.div`
  z-index: 10;
  background: ${colors.white};
  border: 0.5px solid #d5d8db;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Member = styled.div`
  color: black;
`;

export const MemberName = styled.div``;

export const MemberMail = styled.div`
  color: #929497;
`;

export const Line = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background 120ms ease-in 0s;
  :hover {
    cursor: pointer;
    background: #eff2f5;
  }
  :active {
    background: #d5d8db;
  }
`;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
`;

export const DropdownMenuWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  padding: 8px 0;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  animation-name: ${resizeAnimation};
  animation-duration: 200ms;
  animation-timing-function: ease-in;
`;

export const DropdownMenuItem = styled.div`
  user-select: none;
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 18px;
  transition: background 80ms ease-in 0s;
  cursor: pointer;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: ${colors.grayHover};
  }
`;

export const ClassicLink = styled.a`
  color: ${primaryColors.main};
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  transition: color 120ms ease-in 0s;

  :hover,
  :focus {
    color: ${primaryColors.main};
  }
`;

export const MobilePageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
  border-bottom: 1px solid ${colors.gray200};
  margin-bottom: 20px;
`;

/**
 * ======================================================
 *        Mobile based design components here
 * ======================================================
 */

export const CardMobile = styled.div<{
  withMarginBottom?: boolean;
  stretch?: boolean;
}>`
  background-color: ${colors.gray50};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${(props) => (props.stretch ? 'stretch' : 'center')};
  height: 100%;
  min-height: 56px;
  padding: 6px 8px;
  width: 100%;
  ${(props) => props.withMarginBottom && 'margin-bottom: 8px;'};
`;

export const CardMobileLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardMobileRight = styled(CardMobileLeft)<{ alignStart?: boolean }>`
  align-items: ${(props) => (props.alignStart ? 'flex-start' : 'center')};
  height: 100%;
`;

export const CardMobileTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray850};
  font-weight: normal;
`;

export const CardMobileSubtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray500};
  margin-top: 7px;
`;

export const InfoHeader = styled.div`
  margin-bottom: 16px;
`;

export const InfoIconUi = styled.div`
  margin-right: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

export const InfoLabel = styled.div<{ fullWidth?: boolean; large?: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  width: 100%;
  letter-spacing: 0.16px;
  font-weight: 400;
  ${(props) => (props.fullWidth ? 'max-width: 100%' : 'max-width: 85%')};
`;

export const InfoRow = styled.div<{ large?: boolean }>`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
`;

export const LabelEllipsis = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  ${InfoRow}:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid ${colors.gray200};
  color: ${colors.gray500};
  padding-bottom: 8px;
`;

export const TitleRow = styled.div<{ large?: boolean }>`
  color: ${colors.gray850};
  ${(props) =>
    props.large
      ? `
    color: ${colors.gray850};
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
    max-width: calc(100% - 24px);
  `
      : `
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  `}
`;

export const BottomSheetPlaceholder = styled.div<{ height?: number }>`
  width: 100%;
  height: 0;
  visibility: hidden;
  transition: height 0.75s; /* transition at closing */

  &.open {
    height: ${(props) => (props.height ? `${props.height}px` : '0')};
    padding-top: 16px;
    transition: height 1s; /* transition at opening */
  }
`;

const baseActionButtonStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 26px;
  background: ${colors.gray100};
  border-radius: 32px;
  padding: 4px 10px;
  text-decoration: none;
  color: ${colors.gray850};

  &:hover,
  &:active {
    text-decoration: none;
    color: ${colors.gray850};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  * + * {
    margin-left: 2px;
  }
`;

export const ActionButton = styled.div`
  ${baseActionButtonStyles}
`;

export const ActionButtonLabel = styled.span`
  color: ${colors.gray850};
  line-height: 18px;
`;

export const ActionLink = styled(Link)`
  ${baseActionButtonStyles};
`;

export const ActionLinkExternal = styled.a`
  ${baseActionButtonStyles};
`;
