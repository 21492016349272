import { GOOGLE_RECAPTCHA_SITE_KEY } from 'config/constants';
import colors from 'constants/colors';
import { passwordRegex } from 'constants/password';
import {
  Button,
  CircularSpinner,
  EyeCrossedIcon,
  EyeIcon,
  FieldInputWrapper,
  TextInput,
} from 'modules/common-ui';
import { IconWrapper } from 'modules/common-ui/components/IconWrapper';
import {
  Card,
  CardFooterContainer,
  CardHeader,
  FieldTitle,
  PrivacyPolicyContainer,
  StyledLink,
} from 'modules/signup/common';
import { type RefObject, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type AccountFormProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  loading: boolean;
  onSubmit: () => void;
  onValueChange: (key: string, v: string | null) => void;
  recaptchaRef: RefObject<ReCAPTCHA>;
};

export const HelperText = styled.div`
  margin-top: 16px;
  color: ${colors.gray500};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const AccountForm = ({
  firstName,
  lastName,
  email,
  password,
  loading,
  recaptchaRef,
  onSubmit,
  onValueChange,
}: AccountFormProps) => {
  const [t] = useTranslation('signup');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <Card width={390}>
        <CardHeader displayLogo={true} title={t('form.title')} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <FieldTitle>{t('form.fields.firstName.label')} *</FieldTitle>
          <TextInput
            variant="secondary"
            fullWidth
            placeholder={t('form.fields.firstName.placeholder')}
            value={firstName}
            onChange={(e) => {
              onValueChange('firstName', e.target.value);
            }}
          />

          <FieldTitle>{t('form.fields.lastName.label')} *</FieldTitle>
          <TextInput
            variant="secondary"
            fullWidth
            placeholder={t('form.fields.lastName.placeholder')}
            value={lastName}
            onChange={(e) => {
              onValueChange('lastName', e.target.value);
            }}
          />

          <FieldTitle>{t('form.fields.email.label')} *</FieldTitle>
          <TextInput
            variant="secondary"
            fullWidth
            type="email"
            placeholder={t('form.fields.email.placeholder')}
            value={email}
            onChange={(e) => {
              onValueChange('email', e.target.value);
            }}
          />

          <FieldTitle>{t('form.fields.password.label')} *</FieldTitle>
          <FieldInputWrapper width="100%">
            <TextInput
              variant="secondary"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              pattern={passwordRegex.source}
              placeholder="xxxx"
              value={password}
              title={t('user:user.resetPassword.form.help')}
              onChange={(e) => {
                onValueChange('password', e.target.value);
              }}
            />
            <IconWrapper
              active={showPassword}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
            </IconWrapper>
          </FieldInputWrapper>
          <HelperText>{t('user:user.resetPassword.form.help')}</HelperText>
          <br />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={(value) => {
              onValueChange('recaptcha', value);
            }}
          />

          <CardFooterContainer>
            <Button
              variant="outline-primary"
              type="submit"
              disabled={loading}
              iconOnly={loading}
              iconLeft={loading ? <CircularSpinner /> : null}
            >
              {t('form.submitLabel')}
            </Button>
            <StyledLink to="/login">{t('form.loginLabel')}</StyledLink>
          </CardFooterContainer>
        </form>
      </Card>
      <PrivacyPolicyContainer
        dangerouslySetInnerHTML={{
          __html: t('form.termsOfService', {
            interpolation: { escapeValue: false },
          }),
        }}
      />
    </div>
  );
};
