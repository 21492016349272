import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import colors from 'constants/colors';

export const Workspace = styled.div<{ withPaddingLeft?: boolean }>`
  border-bottom: 1px solid #d5d8db;
  cursor: pointer;
  display: flex;
  flex-grow: 1 1 1;
  height: 54px;
  padding: 8px;
  padding-bottom: 11px;
  ${(props) => (props.withPaddingLeft ? 'padding-left: 28px;' : '')}
  transition: background 120ms ease-in 0s;

  :hover {
    background: rgba(45, 47, 48, 0.08);
    color: #4b5c7c;
  }

  :focus,
  :active {
    background: ${colors.grayHover};
  }

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    padding: 16px;
    height: 74px;
  }
`;

const _wsthumbnailbg = (noBg: boolean) => {
  if (noBg) return '';
  return colors.gray100;
};

const _wsthumbnailcol = () => {
  return colors.gray500;
};

export const WorkspaceThumbnail = styled.div<{
  noBg?: boolean;
}>`
  width: 32px;
  height: 32px;
  min-width: 32px;
  background: ${(props) => _wsthumbnailbg(!!props.noBg)};
  color: ${(props) => _wsthumbnailcol()};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 20px;
  font-weight: 500;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    height: 42px;
    width: 42px;
  }
`;

export const WorkspaceContent = styled.div`
  flex: 1;
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WorkspaceTitle = styled.div`
  color: ${colors.gray850};
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const WorkspaceSubline = styled.div`
  font-size: 11px;
  height: 16px;
  line-height: 16px;
  color: ${colors.gray500};
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WorkspaceActive = styled.div`
  margin-left: auto;
  padding-left: 16px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
