import type { AssetFileFormData } from 'modules/assets/files/components/AssetFileImportFormModal';
import type { AssetInOrganizationIssueData } from 'modules/assets/issues/components/ReportIssueButton/AssetInOrganizationIssueData.type';
import type { AssetFiltersGQL as InventoryAssetFilters } from 'modules/common-ui/hooks/assetFiltersGQL/types';
import type { FetchApi } from '../client';

export type RequestExportInventoryParams = {
  filters: InventoryAssetFilters;
  search: string;
  columns: string[];
};

export type RequestExportArchivedInventoryParams = {
  search: string;
  columns: string[];
};

export const fleetApi = (fetchApi: FetchApi) => ({
  downloadAssetInOrganizationFile(
    token: string,
    assetInOrganizationFileId: number,
  ): Promise<Response> {
    const endpoint = fetchApi.generateEndpoint(
      `/app/assetInOrganizationFiles/${assetInOrganizationFileId}/download`,
    );

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-access-token': token,
      },
    });
  },
  downloadAssetInOrganizationIssueAttachment(
    token: string,
    assetInOrganizationIssueId: number,
    assetInOrganizationIssueAttachmentId: number,
    onCompleted: (res: Response) => void,
    onError: () => void,
  ): Promise<void> {
    const endpoint = fetchApi.generateEndpoint(
      `/app/assetInOrganizationIssue/${assetInOrganizationIssueId}/attachments/${assetInOrganizationIssueAttachmentId}/download`,
    );

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-access-token': token,
      },
    })
      .then(onCompleted)
      .catch(onError);
  },
  createAssetInOrganizationIssue(
    token: string,
    assetId: number,
    formData: AssetInOrganizationIssueData,
  ): Promise<void> {
    const { files, title, description, isCritical } = formData;
    const data: {
      assetId: number;
      title: string;
      description: string;
      isCritical: string;
    } = {
      assetId,
      title,
      description,
      isCritical: isCritical ? 'true' : 'false',
    };

    return fetchApi
      .setToken(token)
      .postFiles('/app/assetInOrganizationIssue', data, files);
  },
  addAssetInOrganizationIssueAttachment(
    token: string,
    assetInOrganizationIssueId: number,
    files: File[],
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .postFiles(
        `/app/assetInOrganizationIssue/${assetInOrganizationIssueId}/attachments`,
        {},
        files,
      );
  },
  uploadAssetInOrganizationFile(
    token: string,
    assetId: number,
    formData: AssetFileFormData,
  ): Promise<void> {
    const { file, shared, isPublic, name, expirationDate } = formData;
    const data: {
      assetId: number;
      shared: boolean;
      isPublic: boolean;
      name: string;
      expirationDate?: string | null;
    } = {
      assetId,
      shared,
      isPublic,
      name,
    };

    if (expirationDate) {
      data.expirationDate = expirationDate;
    }

    return fetchApi
      .setToken(token)
      .postFile('/app/assetInOrganizationFiles/upload', data, file);
  },
  requestExportInventory(
    token: string,
    params: RequestExportInventoryParams,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/inventory/exportV2', params)
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occured while requesting your export. Please try again later',
        );
      });
  },
  requestExportArchivedInventory(
    token: string,
    params: RequestExportArchivedInventoryParams,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/inventory/exportV2/archived', { ...params, filters: {} })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occured while requesting your export. Please try again later',
        );
      });
  },
});
