import { Tag } from 'modules/common-ui';
import type { CustomFieldV2SelectValueType } from 'modules/types';
import { getTagColorFromString } from '../Tag/colors';

export type CustomFieldV2SelectValueProps = {
  value: CustomFieldV2SelectValueType;
  fullSize?: boolean;
  onClear?: () => void;
};

export const CustomFieldV2SelectValue = ({
  value = { label: '', color: 'default' },
  onClear,
  fullSize = false,
}: CustomFieldV2SelectValueProps) => {
  const { label, color } = value;
  if (!label) return null;

  return (
    <Tag
      id={label}
      label={label}
      onDelete={onClear}
      maxWidth={fullSize ? undefined : 80}
      withTooltip={true}
      color={getTagColorFromString(color)}
    />
  );
};
