import { SingleSelect, Tag } from 'modules/common-ui';
import { getTagColorFromString } from '../Tag/colors';
import type { CategoryType } from './Category.type';

export type CategoryPickerProps = {
  value?: CategoryType | null;
  id?: string;
  categories: CategoryType[];
  onChange: (value: CategoryType | null) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  placeholder: string;
};

export const CategoryPicker = ({
  value,
  id,
  categories,
  onChange,
  onBlur,
  placeholder,
}: CategoryPickerProps) => {
  return (
    <SingleSelect<CategoryType>
      id={id || `${id}-single-select`}
      placeholder={placeholder}
      value={value}
      options={categories}
      formatOption={(option): JSX.Element => {
        return (
          <Tag
            id={option.id}
            label={option.label}
            color={getTagColorFromString(option.color)}
          />
        );
      }}
      onSelect={(option) => {
        onChange(option);
      }}
      onBlur={(e: React.FocusEvent<HTMLElement>) => {
        if (onBlur) {
          onBlur(e);
        }
      }}
      config={{
        isClearable: true,
      }}
    />
  );
};
