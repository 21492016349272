import { isVisitingFromIOS } from './device';

type ParamTypes = {
  latitude: number;
  longitude: number;
};

export const generateMapURLFromCoordinates = ({
  latitude,
  longitude,
}: ParamTypes) => {
  // https://developers.google.com/maps/documentation/urls/get-started?hl=fr seems to be universal now
  let MAP_BASE_URI = 'https://www.google.com/maps/search/?api=1&query=';
  if (isVisitingFromIOS()) {
    MAP_BASE_URI = 'maps://maps.google.com/maps?q=loc:';
  }

  const destinationUrl = `${MAP_BASE_URI}${latitude}%2C${longitude}`;

  return destinationUrl;
};

export const generateMapURLFromAddress = ({ address }: { address: string }) => {
  let MAP_BASE_URI = 'https://www.google.com/maps/search/?api=1&query=';
  if (isVisitingFromIOS()) {
    MAP_BASE_URI = 'maps://maps.google.com/maps?q=';
  }
  const destinationUrl = `${MAP_BASE_URI}${encodeURI(address)}`;
  return destinationUrl;
};

export const generateMapURLFromOriginDestination = ({
  origin,
  destination,
}: { origin: string; destination: string }) => {
  let MAP_BASE_URI = 'https://www.google.com/maps/dir/?api=1&';
  if (isVisitingFromIOS()) {
    MAP_BASE_URI = 'maps://maps.google.com/maps?';
  }
  const url = `${MAP_BASE_URI}origin=${encodeURI(origin)}&destination=${encodeURI(destination)}`;
  return url;
};
