/* eslint-disable max-statements */
import { useQuery } from '@apollo/client';
import { FLAGS } from 'constants/flags';
import { PRICING_PLANS } from 'constants/pricingPlans';
import { ErrorPage } from 'modules/errorPage/ErrorPage';
import layout from 'modules/layout';
import {
  OnboardingPage,
  PlaceholderPage,
  PlaceholderPage2,
  useOnboarding,
} from 'modules/onboarding';
import { SideNav } from 'modules/sidenav/index';
import { updateOrgData as updateOrgDataAction } from 'modules/user/actions';
import { isHibooUser, useFeature } from 'modules/utils';
import { Suspense, useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import type { Store } from 'reducers';
import config from '../../config';
import { type OrganizationData, queryOrgData } from './gql';
import { withAppoloClient } from './hoc';

import { NotificationManager } from 'components/shared/Notifications';
import {
  FeatureFlagsActionTypes,
  useFeatureFlagsDispatch,
} from 'context/feature-flags/FeatureFlagsContext';
import { PersistentReportFiltersProvider } from 'context/filters/PersistentReportFiltersContext';
import { PersistentReportPeriodProvider } from 'context/filters/PersistentReportPeriodContext';
import { apiClient } from 'modules/api-client';
import { CircularSpinner, Spinner } from 'modules/common-ui';
import { useHomepageUrl } from './hooks/useHomepageUrl.hook';
import { useLazyPreloadComponents } from './lazy/lazyPreloader.hook';
import { reactErrorBoundaryHandler } from './onError';

type StateType = {
  organizationId?: number | null;
  assetCount: number | null;
  hasOwnedAsset: boolean | null;
  integrationCount: number | null;
  jobTitle: string | null;
  signUpInProgress: boolean;
  pricingPlan: string | null;
  userToken: string | null;
  workspaceId: number | null;
  email: string | null;
};

function getAndUpdateFlagLocalStorage(name: string, flag: boolean | undefined) {
  const getDataLocalStorage = !!localStorage.getItem(name);
  if (flag === undefined) {
    if (getDataLocalStorage !== undefined) {
      return getDataLocalStorage;
    }
  } else if (
    getDataLocalStorage === undefined ||
    !!getDataLocalStorage !== flag
  ) {
    if (flag === true) {
      localStorage.setItem(name, 'true');
    } else if (flag === false) {
      localStorage.setItem(name, 'false');
    }
    return flag;
  } else {
    return flag;
  }
}

/* eslint-disable-next-line complexity, max-statements */
export const Main = withAppoloClient(() => {
  const {
    organizationId,
    assetCount,
    hasOwnedAsset,
    integrationCount,
    jobTitle,
    signUpInProgress,
    pricingPlan,
    userToken,
    workspaceId,
    email,
  } = useSelector<Store, StateType>((state) => {
    return {
      organizationId: state.user.organizationId,
      assetCount: state.user.assetCount,
      hasOwnedAsset: state.user.hasOwnedAsset,
      integrationCount: state.user.integrationCount,
      jobTitle: state.user.jobTitle,
      signUpInProgress: state.user.signUpInProgress,
      pricingPlan: state.user.organizationPlan,
      userToken: state.user.token,
      workspaceId: state.user.workspaceId,
      email: state.user.email,
    };
  });

  // Preload the pages after the first rendering. It helps to reduce the loading time when the user navigates to the page.
  const {
    AdministrationPagesLazy,
    AlertsConfigurationPagesLazy,
    AllConnectedTyresPageLazy,
    AssetPagesLazy,
    CarbonEmissionsFleetPageLazy,
    CarbonEmissionsPageLazy,
    CarbonEmissionsPageNewLazy,
    CarbonEmissionsPlaceholderNewLazy,
    CatalogPageLazy,
    CsmSecretPageLazy,
    DashboardPageLazy,
    DestinationsPageLazy,
    EquipmentFaultsLazy,
    NewFleetDataPageLazy,
    FleetUsagePageLazy,
    HourlyConsumptionPageLazy,
    HourlyConsumptionPageNewLazy,
    HourlyConsumptionPlaceHolderNewLazy,
    IdleRatePageLazy,
    IdleRatePageNewLazy,
    IdleRatePlaceholderNewLazy,
    IntegrationsPageLazy,
    InventoryPagesLazy,
    SharingPagesLazy,
    JoinOrganizationPagesLazy,
    MapLazy,
    ProfilePageLazy,
    RiskyTyresPageLazy,
    SettingsPageLazy,
    TyreConditionsPlaceholderLazy,
    UsageHoursPageNewLazy,
    UsageHoursPlaceholderNewLazy,
    UserJobPageLazy,
    WorkspaceCreatePageLazy,
    ZoneCreatePageLazy,
    ZoneEditPageLazy,
    ZonePageLazy,
    ZonesListPageLazy,
    ExportsPagesLazy,
  } = useLazyPreloadComponents();

  const featureFlagsDispatch = useFeatureFlagsDispatch();
  let appSyncOnly: boolean | undefined = undefined;
  let destinationsFlag: boolean | undefined = undefined;
  let noReport: boolean | undefined = undefined;
  let carbonEmissionsV2Flag: boolean | undefined = undefined;
  let usageHoursReportFlag: boolean | undefined = undefined;
  let idleReportV2Flag: boolean | undefined = undefined;
  let tyreReportFlag: boolean | undefined = undefined;
  let hourlyConsumptionV2Flag: boolean | undefined = undefined;

  const isOnboarding = useOnboarding();
  const [homepageConfig, isHomepageFeatureFlagLoading] = useHomepageUrl({
    organizationId,
    isOnboarding,
  });

  if (organizationId) {
    const [appSyncOnlyFlag] = useFeature(FLAGS.APP_SYNC_ONLY);
    const [noReportFlag] = useFeature(FLAGS.NO_REPORT);
    [destinationsFlag] = useFeature(FLAGS.DESTINATIONS);
    [carbonEmissionsV2Flag] = useFeature(
      FLAGS.ANALYTICS_CARBON_EMISSIONS_REPORT,
    );
    [usageHoursReportFlag] = useFeature(FLAGS.ANALYTICS_USAGE_HOURS_REPORT);
    [idleReportV2Flag] = useFeature(FLAGS.ANALYTICS_IDLE_RATE_REPORT);
    [hourlyConsumptionV2Flag] = useFeature(
      FLAGS.ANALYTICS_HOURLY_CONSUMPTION_REPORT,
    );
    [tyreReportFlag] = useFeature(FLAGS.TYRE_V2);
    appSyncOnly = appSyncOnlyFlag;
    noReport = noReportFlag;

    tyreReportFlag = getAndUpdateFlagLocalStorage(
      'tyreReportFlag',
      tyreReportFlag,
    );

    usageHoursReportFlag = getAndUpdateFlagLocalStorage(
      'usageHoursReportFlag',
      usageHoursReportFlag,
    );

    idleReportV2Flag = getAndUpdateFlagLocalStorage(
      'idleReportV2Flag',
      idleReportV2Flag,
    );

    hourlyConsumptionV2Flag = getAndUpdateFlagLocalStorage(
      'hourlyConsumptionV2Flag',
      hourlyConsumptionV2Flag,
    );

    carbonEmissionsV2Flag = getAndUpdateFlagLocalStorage(
      'carbonEmissionsV2Flag',
      carbonEmissionsV2Flag,
    );
  }

  const dispatch = useDispatch();
  const updateOrgData = useCallback(
    (
      hasOwnedAsset: boolean,
      assetCount: number | null,
      integrationCount: number | null,
    ) => {
      dispatch(
        updateOrgDataAction(hasOwnedAsset, assetCount, integrationCount),
      );
    },
    [dispatch],
  );

  const { data, startPolling, stopPolling } = useQuery<OrganizationData>(
    queryOrgData,
    {
      fetchPolicy: 'cache-and-network',
      pollInterval: 60000, // poll every min
      skip: Boolean(hasOwnedAsset),
    },
  );

  const location = useLocation();

  useEffect(() => {
    if (
      config.segmentTracking &&
      config.SEGMENT_TOKEN &&
      window.analytics &&
      email &&
      !isHibooUser(email)
    ) {
      window.analytics.page();
    }

    if (email && isHibooUser(email)) {
      // Stop all tracking for hiboo users
      window.SEGMENT_TOKEN = '';
    }
  }, [location, email]);

  useEffect(() => {
    if (data?.viewer?.organization) {
      const { hasOwnedAsset, assetsCount, integrationsCount } =
        data.viewer.organization;
      updateOrgData(hasOwnedAsset, assetsCount, integrationsCount);
    }
  }, [data, updateOrgData]);

  useEffect(() => {
    if (!hasOwnedAsset && integrationCount) {
      startPolling(60000);
    } else {
      stopPolling();
    }
  }, [hasOwnedAsset, integrationCount, startPolling, stopPolling]);

  const getAllFeatureFlags = useCallback(async () => {
    if (!userToken) {
      return;
    }
    const featureFlags = await apiClient.flag.getAllFlags(userToken);
    featureFlagsDispatch({
      type: FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS,
      payload: featureFlags,
    });
  }, [userToken, featureFlagsDispatch]);

  const clearAllFeatureFlags = useCallback(() => {
    featureFlagsDispatch({
      type: FeatureFlagsActionTypes.LOAD_FEATURE_FLAGS,
      payload: {},
    });
  }, [featureFlagsDispatch]);

  useEffect(() => {
    if (userToken) {
      getAllFeatureFlags();
    }
    // Reset features flags when user logout
    return () => {
      clearAllFeatureFlags();
    };
  }, [getAllFeatureFlags, clearAllFeatureFlags, userToken]);

  // if no organization: redirect to onboarding flow
  if (!organizationId) {
    return (
      <layout.Root>
        <Routes>
          <Route
            path="/create-organization"
            element={
              <Suspense fallback={<CircularSpinner />}>
                <JoinOrganizationPagesLazy />
              </Suspense>
            }
          />
          <Route path="/" element={<Navigate to="/create-organization" />} />
        </Routes>
      </layout.Root>
    );
  }

  // if no jobTitle and the user is signing up for the first time
  if (!jobTitle && organizationId && signUpInProgress) {
    return (
      <layout.Root>
        <Routes>
          <Route
            path="/add-user-job"
            element={
              <Suspense fallback={<CircularSpinner />}>
                <UserJobPageLazy />
              </Suspense>
            }
          />
          <Route path="/" element={<Navigate to="/add-user-job" />} />
        </Routes>
      </layout.Root>
    );
  }

  // DISPLAY APP SYNC ONLY
  if (appSyncOnly === true) {
    return (
      <layout.Root>
        <SideNav appSyncOnly destinationsFlag={destinationsFlag} />
        <layout.PageContainer>
          <Routes>
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <DashboardPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/integrations"
              element={
                integrationCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <IntegrationsPageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage2 />
                )
              }
            />
            <Route
              path="/destinations"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <DestinationsPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/administration/*"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <AdministrationPagesLazy />
                </Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <ProfilePageLazy />
                </Suspense>
              }
            />
            <Route
              path="/catalog"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <CatalogPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/settings/organization"
              element={<Navigate to="/settings/general" />}
            />
            <Route
              path="/settings/*"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <SettingsPageLazy />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                !isOnboarding ? (
                  <Navigate to="/integrations" />
                ) : (
                  <Navigate to="/onboarding" />
                )
              }
            />
          </Routes>
        </layout.PageContainer>
      </layout.Root>
    );
  }

  const renderLoading = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Spinner />
    </div>
  );

  const rootToHomepage = () => {
    if (isHomepageFeatureFlagLoading) {
      return renderLoading();
    }
    return <Navigate to={homepageConfig} />;
  };

  // else
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error) =>
        reactErrorBoundaryHandler(error, organizationId, workspaceId)
      }
    >
      <NotificationManager />
      <layout.Root>
        <SideNav
          appSyncOnly={false}
          noReport={noReport}
          destinationsFlag={destinationsFlag}
          usageHoursReportFlag={usageHoursReportFlag}
          tyreReportFlag={tyreReportFlag}
        />
        <layout.PageContainer>
          <Routes>
            <Route path="/onboarding" element={<OnboardingPage />} />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <DashboardPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/map"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <MapLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            {/* alerts */}
            <Route
              path="/alerts/configuration/*"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <AlertsConfigurationPagesLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            {/* assets */}
            <Route
              path="/assets/:assetId/*"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <AssetPagesLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            <Route
              path="/inventory/*"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <InventoryPagesLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            <Route
              path="/sharing/*"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <SharingPagesLazy />
                </Suspense>
              }
            />
            {/* zones v2 */}
            <Route
              path="/zones2/create"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <ZoneCreatePageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            <Route
              path="/zones2/edit/:zoneId"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <ZoneEditPageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            <Route path="/zones2" />

            {/* zones */}
            <Route
              path="/zones/:addressId/*"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <ZonePageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />
            <Route
              path="/zones"
              element={
                assetCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <ZonesListPageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage />
                )
              }
            />

            {/* others */}
            <Route
              path="/settings/organization"
              element={<Navigate to="/settings/general" />}
            />
            <Route
              path="/settings/*"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <SettingsPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/integrations"
              element={
                integrationCount ? (
                  <Suspense fallback={<CircularSpinner />}>
                    <IntegrationsPageLazy />
                  </Suspense>
                ) : (
                  <PlaceholderPage2 />
                )
              }
            />
            <Route
              path="/destinations"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <DestinationsPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/administration/*"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <AdministrationPagesLazy />
                </Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <ProfilePageLazy />
                </Suspense>
              }
            />
            <Route
              path="/catalog"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <CatalogPageLazy />
                </Suspense>
              }
            />
            <Route
              path="/workspaces/new"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <WorkspaceCreatePageLazy />
                </Suspense>
              }
            />

            <Route
              path="/csm/secret"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <CsmSecretPageLazy />
                </Suspense>
              }
            />

            <Route
              element={
                <PersistentReportFiltersProvider>
                  <PersistentReportPeriodProvider>
                    <Outlet />
                  </PersistentReportPeriodProvider>
                </PersistentReportFiltersProvider>
              }
            >
              {/* reports */}
              <Route
                path="/reports/equipment-faults"
                element={
                  assetCount ? (
                    <Suspense fallback={<CircularSpinner />}>
                      <EquipmentFaultsLazy />
                    </Suspense>
                  ) : (
                    <PlaceholderPage />
                  )
                }
              />
              <Route
                path="/reports/fleet-data"
                element={
                  assetCount ? (
                    <Suspense fallback={<CircularSpinner />}>
                      <NewFleetDataPageLazy />
                    </Suspense>
                  ) : (
                    <PlaceholderPage />
                  )
                }
              />

              <Route
                path="/reports/carbon-emissions/fleet"
                element={
                  !carbonEmissionsV2Flag ? (
                    <Navigate to="/analytics/carbon-emissions/" replace />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <CarbonEmissionsFleetPageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/carbon-emissions/overview"
                element={
                  !carbonEmissionsV2Flag ? (
                    <Navigate to="/analytics/carbon-emissions" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <CarbonEmissionsPageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/carbon-emissions"
                element={<Navigate to="/reports/carbon-emissions/overview" />}
              />

              <Route
                path="/reports/fleet-usage"
                element={
                  !usageHoursReportFlag ? (
                    <Navigate to="/analytics/usage-hours" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <FleetUsagePageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/idle-rate"
                element={
                  !idleReportV2Flag ? (
                    <Navigate to="/analytics/idle-rate" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <IdleRatePageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/hourly-consumption"
                element={
                  !hourlyConsumptionV2Flag ? (
                    <Navigate to="/analytics/hourly-consumption" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <HourlyConsumptionPageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/tyre-conditions/all-connected/request-beta"
                element={
                  tyreReportFlag ? (
                    <Navigate to="/reports/tyre-conditions/all-connected" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <TyreConditionsPlaceholderLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/tyre-conditions/risky-tyres/request-beta"
                element={
                  tyreReportFlag ? (
                    <Navigate to="/reports/tyre-conditions/risky-tyres" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <TyreConditionsPlaceholderLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/tyre-conditions/all-connected"
                element={
                  !tyreReportFlag ? (
                    <Navigate to="/reports/tyre-conditions/all-connected/request-beta" />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <AllConnectedTyresPageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/reports/tyre-conditions/risky-tyres"
                element={
                  !tyreReportFlag ? (
                    <Navigate
                      to="/reports/tyre-conditions/risky-tyres/request-beta"
                      replace
                    />
                  ) : (
                    <>
                      {assetCount ? (
                        <Suspense fallback={<CircularSpinner />}>
                          <RiskyTyresPageLazy />
                        </Suspense>
                      ) : (
                        <PlaceholderPage />
                      )}
                    </>
                  )
                }
              />

              <Route
                path="/analytics/usage-hours"
                element={
                  usageHoursReportFlag &&
                  pricingPlan !== PRICING_PLANS.freemium ? (
                    assetCount ? (
                      <Suspense fallback={<CircularSpinner />}>
                        <UsageHoursPageNewLazy />
                      </Suspense>
                    ) : (
                      <PlaceholderPage />
                    )
                  ) : (
                    <Suspense fallback={<CircularSpinner />}>
                      <UsageHoursPlaceholderNewLazy />
                    </Suspense>
                  )
                }
              />
              <Route
                path="/analytics/idle-rate"
                element={
                  idleReportV2Flag && pricingPlan !== PRICING_PLANS.freemium ? (
                    assetCount ? (
                      <Suspense fallback={<CircularSpinner />}>
                        <IdleRatePageNewLazy />
                      </Suspense>
                    ) : (
                      <PlaceholderPage />
                    )
                  ) : (
                    <Suspense fallback={<CircularSpinner />}>
                      <IdleRatePlaceholderNewLazy />
                    </Suspense>
                  )
                }
              />
              <Route
                path="/analytics/hourly-consumption"
                element={
                  hourlyConsumptionV2Flag &&
                  pricingPlan !== PRICING_PLANS.freemium ? (
                    assetCount ? (
                      <Suspense fallback={<CircularSpinner />}>
                        <HourlyConsumptionPageNewLazy />
                      </Suspense>
                    ) : (
                      <PlaceholderPage />
                    )
                  ) : (
                    <Suspense fallback={<CircularSpinner />}>
                      <HourlyConsumptionPlaceHolderNewLazy />
                    </Suspense>
                  )
                }
              />
              <Route
                path="/analytics/carbon-emissions"
                element={
                  carbonEmissionsV2Flag &&
                  pricingPlan !== PRICING_PLANS.freemium ? (
                    assetCount ? (
                      <Suspense fallback={<CircularSpinner />}>
                        <CarbonEmissionsPageNewLazy />
                      </Suspense>
                    ) : (
                      <PlaceholderPage />
                    )
                  ) : (
                    <Suspense fallback={<CircularSpinner />}>
                      <CarbonEmissionsPlaceholderNewLazy />
                    </Suspense>
                  )
                }
              />
            </Route>
            <Route
              path="/exports"
              element={
                <Suspense fallback={<CircularSpinner />}>
                  <ExportsPagesLazy />
                </Suspense>
              }
            />
            <Route path="*" element={rootToHomepage()} />
          </Routes>
        </layout.PageContainer>
      </layout.Root>
    </ErrorBoundary>
  );
});
